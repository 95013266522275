import { ThemeProvider } from "@mui/material/styles"
import { CssBaseline, StyledEngineProvider } from "@mui/material"
import React, { useEffect, useRef } from "react"

import { useDispatch, useSelector } from "react-redux"
import {
  ADD_DEVICE_NOTIFICATION,
  PUSH_TO_UNREAD_CONVERSATION,
  SET_DEVICE_NOTIFICATION,
  SET_MESSAGE_NOTIFICATION,
  SET_UNREAD_CONVERSATION,
} from "store/actions"

// routing
import Routes from "routes"

// defaultTheme
import themes from "themes"

import NavigationScroll from "layout/NavigationScroll"
import socketIOClient from "socket.io-client/dist/socket.io.js"
// import socketIOClient from "socket.io-client"
import Axios from "./utils/axios"
import { useNavigate } from "react-router"


var ENDPOINT
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  ENDPOINT = 'http://localhost:8001'
} else {
  ENDPOINT = 'https://oumaima-fatnasi.herokuapp.com'
}

export const socket = socketIOClient(ENDPOINT)


const App = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const { deviceNotification, messageNotification } = useSelector(
    (state) => state.notification
  )
  const dispatch = useDispatch()
  const customization = useSelector((state) => state.customization)
  const navigate = useNavigate()

  // Get unread notifications
  useEffect(async () => {
    if (!currentUser) return
    let { deviceNotification, unreadConversation } = (
      await Axios.get(`/user/getUser/${currentUser._id}`)
    ).data

    // Pass backend count in states
    dispatch({
      type: SET_DEVICE_NOTIFICATION,
      payload: deviceNotification,
    })
    dispatch({
      type: SET_UNREAD_CONVERSATION,
      payload: unreadConversation,
    })

  }, [])

  // Socket connection
  useEffect(async () => {
    if (!currentUser) return navigate("/")
    return connectSocket()

    function connectSocket() {
      socket.on("connect", () => {
        console.log(`Socket connection Id : ${socket.id}`)

        socket.emit("registerUserToTheList", currentUser)
      })

      // Add +1 when new message arrives
      socket.on("receivingANewMessage", async (messageData) => {
        dispatch({
          type: PUSH_TO_UNREAD_CONVERSATION,
          payload: messageData.senderId,
        })
      })
      socket.on("alertNotification", async (data) => {
        dispatch({
          type: ADD_DEVICE_NOTIFICATION,
        })
      })

      return () => socket.disconnect()
    }
  }, [currentUser])


  return (
    //   <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
    //   </Provider>
  )
}

export default App


