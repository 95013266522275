import { createContext, useContext, useEffect, useState } from "react"
import useStyles from "./style"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import "./PatientPage.css"
import AddUser from "./AddPatient"
import Axios from "../../utils/axios"
import Fiche from "./fichePatient"

export const listContext = createContext(null)

const DoctorPage = () => {
  const classes = useStyles()
  const [showAddUser, setShowAddUser] = useState(false)
  const [selectedUserData, setSelectedUserData] = useState()
  const [ficheOpen, setFicheOpen] = useState(false)

  const [fetchedData, setFetchedData] = useState([])

  const [pageFeedback, setPageFeedback] = useState("")
  // Get user list
  useEffect(async () => {
    setFetchedData(
      await Axios.get("/doctorList/getDoctors").then((res) => {
        return res.data
      })
    )
  }, [])

  const handleDeletePatient = async (id) => {
    await Axios.delete(`/user/deleteUser/${id}`)
    setPageFeedback("Utilisateur supprimé")
    setFetchedData(fetchedData.filter((user) => user._id !== id))
  }

  const config = {
    pageName: "Docteur",
  }

  {
    /* //! ------------- Return ----------------- */
  }
  return (
    <listContext.Provider
      value={{
        setPageFeedback,
        ficheOpen,
        setFicheOpen,
        selectedUserData,
        setSelectedUserData,
        setFetchedData,
        fetchedData,
      }}
    >
      <div className="patientPage">
        <div className={classes.flex}>{renderAboveTable()}</div>
        {RenderTable()}
        {renderEditPatientDialog()}
      </div>
      <Fiche />
    </listContext.Provider>
  )

  function renderAboveTable() {
    return (
      <>
        <div>
          <h2>
            {config.pageName} de :
            <strong style={{ color: "rgb(72,41,178)" }}>
              &nbsp;{"Etablisment"}
            </strong>
          </h2>
          <h3>{pageFeedback} </h3>
        </div>
        <IconButton
          color="primary"
          aria-label="add"
          className={classes.iconButton}
          onClick={() => {
            setSelectedUserData(null)
            setShowAddUser(true)
          }}
        >
          <AddIcon />
        </IconButton>
      </>
    )
  }

  function RenderTable() {
    return (
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>{renderTableHeader()}</TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  function renderTableBody() {
    return fetchedData.map((user, key) => (
      <TableRow
        onClick={() => {
          setSelectedUserData(user)
          setFicheOpen(true)
        }}
        key={key}
      >
        <TableCell>{user.username}</TableCell>
        <TableCell>{user?.cin}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>
          <EditIcon
            onClick={(e) => {
              e.stopPropagation()
              setShowAddUser(true)
              setSelectedUserData(user)
            }}
            style={{ color: "#1bb719", cursor: "pointer" }}
          />
          <DeleteForeverIcon
            onClick={(e) => {
              e.stopPropagation()
              handleDeletePatient(user._id)
            }}
            style={{ color: "#b54827", cursor: "pointer" }}
          />
        </TableCell>
      </TableRow>
    ))
  }

  function renderEditPatientDialog() {
    return (
      <Dialog open={showAddUser} onClose={() => setShowAddUser(false)}>
        <Paper className={classes.paper}>
          <AddUser
            setShowAddUser={setShowAddUser}
            selectedUserData={selectedUserData}
          />
        </Paper>
      </Dialog>
    )
  }

  function renderTableHeader() {
    return (
      <TableRow>
        <TableCell>Nom</TableCell>
        <TableCell>Cin</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    )
  }
}

export default DoctorPage
