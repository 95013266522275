import { Button, Grid, TextField, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"

import { makeStyles } from "@mui/styles"
import Axios from "../../utils/axios"
import { listContext } from "./DoctorPage"


export default function AddUser({ setShowAddUser, selectedUserData, mode }) {
  mode = "doctor"
  const { setPageFeedback, setFetchedData, fetchedData } =
    useContext(listContext)
  const [feedback, setFeedback] = useState("")
  const [formData, setFormData] = useState({
    username: selectedUserData?.username || "",
    email: selectedUserData?.email || "",
    password: "1111",
    cin: selectedUserData?.cin || "",

    birthday: selectedUserData?.birthday || "",

    phone: selectedUserData?.phone || "",

    address: selectedUserData?.address || "",
    city: selectedUserData?.city || "",
    specialty: selectedUserData?.specialty || "",
    officeAddress: selectedUserData?.officeAddress || "",
    role: "doctor",
  })

  useEffect(() => {
    // TODO TESTING ONLY IN DEV
    if (Axios.defaults.baseURL.indexOf("localhost") !== -1)
      setFormData({
        username: selectedUserData?.username || "DocteurX",
        email: selectedUserData?.email || "DocteurX@gmail.com",
        password: "1111",
        cin: selectedUserData?.cin || "12231454",

        birthday: selectedUserData?.birthday || "12/12/2000",

        phone: selectedUserData?.phone || "98654714",

        address: selectedUserData?.address || "Quelque part",
        city: selectedUserData?.city || "Sousse",
        specialty: selectedUserData?.specialty || "Special",
        officeAddress: selectedUserData?.officeAddress || "Très loin",
        role: "doctor",
      })
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!formData.username) {
      formData.username =
        mode === "doctor" ? "Nouveau docteur" : "Nouveau patient"
    }

    // Modify user request
    if (selectedUserData) {
      let { data } = await Axios.patch(
        `/user/modifyUser/${selectedUserData._id}`,
        formData
      )

      setShowAddUser(false)
      setPageFeedback(`${selectedUserData.username} a été  Modifié.`)
      return setFetchedData(
        fetchedData.map((user) => (user._id === data._id ? data : user))
      )
    }

    // Add user request
    try {
      let { data } = await Axios.post("/user/addUser", formData)
      setShowAddUser(false)
      setPageFeedback(`${data.username} a été Ajouté.`)
      setFetchedData([...fetchedData, data])
    } catch (error) {
      setFeedback(error.response.data)
    }
  }

  return (
    <form>
      <Grid container spacing={3} className="griditem">
        <Grid item xs={12}>
          <Typography variant="h4" style={{ marginTop: "10px" }}>
            {selectedUserData ? "Modification" : "Ajout"} de patient{" "}
          </Typography>
        </Grid>
        {inputRender({
          value: "username",
          label: "Nom et prénom",
        })}
        {inputRender({ value: "email", label: "Email" })}
        {inputRender({ value: "password", label: "Mot de passe" })}
        {inputRender({ value: "cin", label: "Carte d'identité" })}
        {inputRender({ value: "birthday", label: "Date d'anniversaire" })}
        {inputRender({ value: "phone", label: "Numéro de téléphone" })}
        {inputRender({ value: "address", label: "Adresse" })}
        {inputRender({ value: "city", label: "Pays" })}
        {inputRender({ value: "specialty", label: "Spécialité" })}
        {inputRender({ value: "officeAddress", label: "Adresse cabinet" })}
      </Grid>
      <h3 style={{ color: "red" }}>{feedback}</h3>

      <Button
        style={{ marginRight: "10px" }}
        variant="contained"
        color="secondary"
        type="submit"
        onClick={(e) => handleSubmit(e)}
      >
        {selectedUserData ? "Modifier" : "Enregistrer"}
      </Button>
      <Button
        onClick={() => setShowAddUser(false)}
        variant="contained"
        color="primary"
      >
        Annuler
      </Button>
    </form>
  )

  function inputRender({ value, label }) {
    return (
      <Grid item xs={6}>
        <TextField
          fullWidth
          name={value}
          label={label}
          value={formData[value]}
          onChange={(e) =>
            setFormData({ ...formData, [value]: e.target.value })
          }
        />
      </Grid>
    )
  }
}
