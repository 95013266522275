import { applyMiddleware, configureStore } from "@reduxjs/toolkit"
import thunk from "redux-thunk"

import customizationReducer from "./customizationReducer"
import notification from "./reducers/notificationReducer"

export const store = configureStore(
  {
    reducer: {
      customization: customizationReducer,
      notification: notification,
    },
  },
  applyMiddleware(thunk)
)
