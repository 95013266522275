// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// Notification
export const SET_DEVICE_NOTIFICATION = 'SET_DEVICE_NOTIFICATION';
export const ADD_DEVICE_NOTIFICATION = 'ADD_DEVICE_NOTIFICATION';


export const SET_MESSAGE_NOTIFICATION = 'SET_MESSAGE_NOTIFICATION';
export const SET_UNREAD_CONVERSATION = 'SET_UNREAD_CONVERSATION';
export const PUSH_TO_UNREAD_CONVERSATION = 'PUSH_TO_UNREAD_CONVERSATION';
export const PULL_FROM_UNREAD_CONVERSATION = 'PULL_FROM_UNREAD_CONVERSATION';
