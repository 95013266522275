import { useEffect, useState } from 'react';

import './EtatDetaille.css';
// import axios from '../../components/axios';
 /* eslint-disable */
const EtatDetaille = () => {return (
        <div className="etat-detaille">
            <div className="wrapper">
                {/* {Select()} */}

                <div className="container container-1">
                    <p>Identificateur de l&apos;appareil</p>
                    <strong>2F56GB</strong>
                    <p>Identificateur de l&apos;appareil</p>
                    <strong>2F56GB</strong>
                    <p>Identificateur de l&apos;appareil</p>
                    <strong>2F56GB</strong>
                    <p>Identificateur de l&apos;appareil</p>
                    <strong>2F56GB</strong>
                </div>

                <div className="container container-2">
                    <h4>GRAPHES ECG</h4>
                </div>
                <div className="grid">
                    <div className="container container-3">
                        <h4>DOSSIER MÉDICAL DE PATIENT(E)</h4>
                        <p>Identificateur de l&apos;appareil</p>
                        <strong>2F56GB</strong>
                        <p>Identificateur de l&apos;appareil</p>
                        <strong>2F56GB</strong>
                        <p>Identificateur de l&apos;appareil</p>
                        <strong>2F56GB</strong>
                        <div className="remarque">
                            <strong>Remarques :</strong>
                            <ul>
                                <li>
                                    20/03/2022 <br />
                                    <strong>le patient est........</strong>
                                </li>
                                <li>
                                    20/03/2022 <br />
                                    <strong>le patient consulte....</strong>
                                </li>
                                <li>
                                    20/03/2022 <br />
                                    <strong>le patient souffre d&apos;une insuffisance........</strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="container-4 container">
                        <h4>GÉOLOCALISATION</h4>
                        <div>
                            <p>Le partage de géolocalisation est désactivé</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
};

export default EtatDetaille;
