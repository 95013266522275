import {
  Button, Grid,
  TextField,
  Typography
} from "@mui/material"
import { useContext, useState } from "react"

import { makeStyles } from "@mui/styles"
import Axios from "../../utils/axios"
import { patientListContext } from "./PatientPage"
import moment from "moment"


export default function AddPatient({ setShowAddPatient, patientData }) {
  const { setPatientListFeedback, rerender, setRerender } =
    useContext(patientListContext)
  const [feedback, setFeedback] = useState("")

  const [formData, setFormData] = useState({
    username: patientData?.username || "",
    email: patientData?.email || "",
    password: "",
    cin: patientData?.cin || "",

    birthday: patientData?.birthday ? moment(patientData?.birthday).format("DD-MM-YYYY") : "",

    phone: patientData?.phone || "",

    address: patientData?.address || "",
    city: patientData?.city || "",
    role: "patient",
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!formData.username) formData.username = "Nouveau patient"

    if (patientData) {
      Axios.patch(
        `/user/patientList/modifyPatient/${patientData._id}`,
        formData
      )
        .then((res) => {
          setShowAddPatient(false)
          setPatientListFeedback(
            `Patient ${patientData.username} a été  Modifié`
          )
          setRerender(!rerender)
        })
        .catch((err) => {
          setFeedback(err.response.data)
        })

      return
    }
    Axios.post("/user/addUser", formData)
      .then((res) => {
        setShowAddPatient(false)
        setPatientListFeedback(`Patient ${formData.username} a été  Ajouté`)
        setRerender(!rerender)
      })
      .catch((err) => {
        setFeedback(err.response.data)
      })
  }

  return (
    <form>
      <Grid container spacing={3} className="griditem">
        <Grid item xs={12}>
          <Typography variant="h4" style={{ marginTop: "10px" }}>
            {patientData ? "Modification" : "Ajout"} de patient{" "}
          </Typography>
        </Grid>
        {inputRender({
          value: "username",
          label: "Nom et prénom",
        })}
        {inputRender({ value: "email", label: "Email" })}
        {inputRender({ value: "password", label: "Mot de passe" })}
        {inputRender({ value: "cin", label: "Carte d'identité" })}
        {inputRender({ value: "birthday", label: "Date d'anniversaire" })}
        {inputRender({ value: "phone", label: "Numéro de téléphone" })}
        {inputRender({ value: "address", label: "Adresse" })}
        {inputRender({ value: "city", label: "Pays" })}
      </Grid>
      <h3 style={{ color: "red" }}>{feedback}</h3>

      <Button
        style={{ marginRight: "10px" }}
        variant="contained"
        color="secondary"
        type="submit"
        onClick={(e) => handleSubmit(e)}
      >
        {patientData ? "Modifier" : "Enregistrer"}
      </Button>
      <Button
        onClick={() => setShowAddPatient(false)}
        variant="contained"
        color="primary"
      >
        Annuler
      </Button>
    </form>
  )

  function inputRender({ value, label }) {
    return (
      <Grid item xs={6}>
        <TextField
          fullWidth
          name={value}
          label={label}
          value={formData[value]}
          onChange={(e) =>
            setFormData({ ...formData, [value]: e.target.value })
          }
        />
      </Grid>
    )
  }
}
