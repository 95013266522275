// assets
import { IconDashboard } from "@tabler/icons"
import {
  FaHeartbeat,
  FaBell,
  FaHome,
  FaUser,
  FaFileMedicalAlt,
} from "react-icons/fa"
import { MdPermContactCalendar } from "react-icons/md"
import { AiFillWechat } from "react-icons/ai"
import { IconKey } from "@tabler/icons"
import { IoSettings } from "react-icons/io5"

const currentUser  = JSON.parse(localStorage.getItem('user'))

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children:
  currentUser?.role === "admin"
      ? [
          {
            id: "doctorList",
            title: "Docteurs",
            type: "item",
            url: "/dashboard/doctorPage",
            icon: MdPermContactCalendar,
          },
        ]
      : [
          {
            id: "default",
            title: "Accueil",
            type: "item",
            url: "/dashboard/default",
            icon: FaHome,
            breadcrumbs: false,
          },
          // {
          //   id: "État détaillé",
          //   title: "État détaillé",
          //   type: "item",
          //   url: "/dashboard/etatDetaille",

          //   icon: icons.IconDashboard,
          // },
          {
            id: "Patient",
            title: "Patient",
            type: "item",
            url: "/dashboard/patient",

            icon: MdPermContactCalendar,
          },
          {
            id: "Notifications",
            title: "Notifications",
            url: "/dashboard/notifications",
            type: "item",
            icon: FaBell,
            breadcrumbs: false,
          },
          {
            id: "Messagerie",
            title: "Messagerie",
            type: "item",
            url: "/dashboard/messagerie",

            icon: AiFillWechat,
            breadcrumbs: false,
          },
          {
            id: "Profil",
            title: "Profil",
            type: "item",
            url: "/dashboard/profil",
            icon: FaUser,
            breadcrumbs: false,
          },

          {
            id: "Paramètre profile",
            title: "Paramètre profile",
            type: "item",
            url: "/dashboard/accountSetting",

            icon: IoSettings,
            breadcrumbs: false,
          },
        ],
}

export default dashboard
