import React, { useEffect, useState } from "react"
import "./Profile.css"
import { Button, Box, TextField } from "@mui/material"
import Axios from "../../utils/axios"

const currentUser = JSON.parse(localStorage.getItem("user"))
export default function AccountSetting() {
  const [feedback, setFeedback] = useState("")
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  })

  // Fetch user data
  useEffect(async () => {
    let fetchedUserData = (
      await Axios.get(`/user/accountSetting/getUser/${currentUser._id}`)
    ).data

    setFormData({
      ...formData,
      ...fetchedUserData,
    })
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()

    setFeedback("")
    // Validate Password
    if (formData.newPassword != formData.confirmPassword) {
      setFeedback("Les mots de passe ne sont pas identiques")
      return
    }
    // Validate Email
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email) &&
      formData.email
    ) {
      setFeedback("Email invalide")
      return
    }

    Axios.put(`/user/accountSetting/modify/${currentUser._id}`, formData)
      .then((res) => {
        setFeedback(res.data)
      })
      .catch((err) => {
        setFeedback(err.response.data)
      })
  }
  return (
    <div className="profilePage">
      <div className="container">
        <h1>Paramètre de compte</h1>

        <form onSubmit={handleSubmit}>
          {inputRender({ value: "username", label: "Nom d'utilisateur" })}
          {inputRender({ value: "email", label: "Email" })}
          {inputRender({ value: "phone", label: "Numéro de téléphone" })}
          {inputRender({ value: "oldPassword", label: "Ancien mot de passe" })}
          {inputRender({ value: "newPassword", label: "Nouveau Mot de pass" })}
          {inputRender({
            value: "confirmPassword",
            label: "Confirmer le Mot de pass",
          })}
          {feedback && (
            <h3 style={{ margin: 0 }} className="errorMessage">
              {feedback}{" "}
            </h3>
          )}
          <Box textAlign="center">
            <Button type="submit" variant="contained">
              Confirmer
            </Button>
          </Box>
        </form>
      </div>
    </div>
  )

  function inputRender({ value, label }) {
    return (
      <TextField
        fullWidth
        name={value}
        label={label}
        value={formData[value]}
        variant="standard"
        onChange={(e) => setFormData({ ...formData, [value]: e.target.value })}
      />
    )
  }
}
