import React, { useEffect, useState, useContext } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { Divider, Grid, Paper, Select, Typography } from "@mui/material"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid"
import CakeIcon from "@mui/icons-material/Cake"
import ShortTextIcon from "@mui/icons-material/ShortText"
import HomeIcon from "@mui/icons-material/Home"
import InfoIcon from "@mui/icons-material/Info"
import LocationCityIcon from "@mui/icons-material/LocationCity"
import "./fichePatient.css"
import { makeStyles } from "@mui/styles"
import { patientListContext } from "./PatientPage"

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  seen: {
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    backgroundColor: "#CCC",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  tabHeader: {
    backgroundColor: "#b3294e",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 2rem 1rem",
  },
  displayRows: {
    cursor: "pointer",
    fontSize: "2rem",
    color: "#fff",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: "#CCC",
    },
  },
  displayMessenger: {
    cursor: "pointer",
    fontSize: "2rem",
    color: "#080",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: "#CCC",
    },
  },
  doctorMessage: {
    cursor: "pointer",
    transition: "all 0.5s",
    background: "#b2bce1",
  },
  adminMessage: {
    cursor: "pointer",
    transition: "all 0.5s",
    background: "#e6a2ad",
  },
}))
export default function FichePatient(props) {
  const { patientData, setFicheOpen } = useContext(patientListContext)
  const { showMe, patient } = props

  return (
    <Dialog
      open={showMe}
      onClose={() => setFicheOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          backgroundColor: "#00800050",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography style={{ marginTop: "30px", color: "#fff" }}>
          Fiche Patient
        </Typography>
        <Typography
          variant="h2"
          style={{ marginBottom: "10px", color: "#fff" }}
        >
          {patientData?.username}
        </Typography>
      </div>

      <div className="window">
        <main className="mainFiche">
          <DialogContent>
            <Grid container spacing={3} style={{ marginTop: "15px" }}>
              {fieldRender({
                fieldName: "Nom",
                value: "username",
                Icon: InfoIcon,
              })}
              {fieldRender({
                fieldName: "Email",
                value: "email",
                Icon: MailOutlineIcon,
              })}
              {fieldRender({
                fieldName: "Date Anniversaire",
                value: "birthday",
                Icon: CakeIcon,
              })}
              {fieldRender({
                fieldName: "Adresse",
                value: "address",
                Icon: HomeIcon,
              })}
              {fieldRender({
                fieldName: "Ville",
                value: "city",
                Icon: LocationCityIcon,
              })}
              {fieldRender({
                fieldName: "Numéro d'identité",
                value: "cin",
                Icon: ShortTextIcon,
              })}
              {fieldRender({
                fieldName: "Téléphone",
                value: "phone",
                Icon: PhoneAndroidIcon,
              })}

              {patientData?.observations?.map((observation, key) => (
                <Grid item xs ={12}>
                  <Typography key={key + 1}> {`Observation ${key} : ${observation}`}</Typography>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </main>
      </div>
      <DialogActions>
        <Button
          onClick={() => {
            setFicheOpen(false)
          }}
          color="primary"
        >
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  )

  function fieldRender({ fieldName, value, Icon }) {
    return (
      <Grid item xs={6}>
        <Typography
          style={{
            display: "flex",
            marginLeft: "5px",
            fontSize: "17px",
            color: "#4829B2",
          }}
        >
          {" "}
          {fieldName}
        </Typography>
        <Paper elevation={0} style={{ display: "flex", padding: "10px" }}>
          <Icon style={{ marginRight: "3px", color: "#b3294e" }} />
          <Divider orientation="vertical" flexItem />
          <Typography style={{ marginLeft: "5px" }}>
            {patientData?.[value]}
          </Typography>
        </Paper>
      </Grid>
    )
  }
}
