// material-ui
import { Typography } from "@mui/material"

import NavGroup from "./NavGroup"

import { FaBell, FaHome, FaUser } from "react-icons/fa"
import { MdPermContactCalendar } from "react-icons/md"
import { AiFillWechat } from "react-icons/ai"
import { IoSettings } from "react-icons/io5"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { BiLogOut } from "react-icons/bi"
import { useSelector } from "react-redux"
const MenuList = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"))
  const { deviceNotification, unreadConversation } = useSelector(
    (state) => state.notification
  )

  var menuItems = [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "group",
      children:
        currentUser?.role === "admin"
          ? [
              {
                id: "doctorList",
                title: "Docteurs",
                type: "item",
                url: "/dashboard/doctorPage",
                icon: MdPermContactCalendar,
              },
            ]
          : [
              {
                id: "default",
                title: "Accueil",
                type: "item",
                url: "/dashboard/default",
                icon: FaHome,
                breadcrumbs: false,
              },
              /* #region   */
              //   {
              //     id: "État détaillé",
              //     title: "État détaillé",
              //     type: "item",
              //     url: "/dashboard/etatDetaille",

              //     icon: icons.IconDashboard,
              //   },
              /* #endregion */
              {
                id: "Patient",
                title: "Patient",
                type: "item",
                url: "/dashboard/patient",

                icon: MdPermContactCalendar,
              },
              {
                id: "Notifications",
                title: "Notifications",
                url: "/dashboard/notifications",
                type: "item",
                icon: FaBell,
                badge: deviceNotification,
                breadcrumbs: false,
              },
              {
                id: "Rendez-vous",
                title: "Rendez-vous",
                url: "/dashboard/appointment",
                type: "item",
                icon: CalendarMonthIcon,
                badge: deviceNotification,
                breadcrumbs: false,
              },
              {
                id: "Messagerie",
                title: "Messagerie",
                type: "item",
                url: "/dashboard/messagerie",
                badge: unreadConversation.length,

                icon: AiFillWechat,
                breadcrumbs: false,
              },
              // {
              //   id: "Profil",
              //   title: "Profil",
              //   type: "item",
              //   url: "/dashboard/profil",
              //   icon: FaUser,
              //   breadcrumbs: false,
              // },

              {
                id: "Paramètre profile",
                title: "Paramètre profile",
                type: "item",
                url: "/dashboard/accountSetting",

                icon: IoSettings,
                breadcrumbs: false,
              },
            ],
    },
    {
      id: "Déconnexion",
      title: "Déconnexion",
      type: "group",
      mode: "logOut",
      style: { position: "absolute", bottom: 0 },
      children: [
        {
          id: "Déconnexion",
          title: "Déconnexion",
          type: "item",
          url: "/login",
          icon: BiLogOut,
          breadcrumbs: false,
        },
      ],
    },
  ]
  const navItems = menuItems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  return <>{navItems}</>
}

export default MenuList
