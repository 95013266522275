import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
    iconButton: {
        margin: 5,
    },
    paper: {
        flexGrow: 1,
        padding: 30,
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
    },
    white: {
        background: '#FFF',
    },
    gray: {
        background: '#de5b5b7d'
    },
    searchBar: {
        minWidth: '250px',
        marginTop: '-16px',
    },
    searchSelect: {
        minWidth: '250px',
        marginRight: '20px',
    }
}));