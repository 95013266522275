import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { useState } from "react"
import { IconButton } from "@mui/material"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  dialogPaper: {
    // minHeight: "50%",
    // maxHeight: "50%",
    marginLeft: "240px",
    minWidth: "30%",
    maxWidth: "30%",
  },
})

export default function ShowDocumentsDialog({
  onConfirm,
  classes,
  title = "Supprimer un post",
  description = "Êtes vous sur de vouloir supprimer ce post ?",
  button,
  iconButton = true,
}) {
  const [open, setOpen] = useState()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div
      style={{ display: "inline" }}
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      {iconButton ? (
        <IconButton onClick={handleClickOpen}>{button}</IconButton>
      ) : (
        { button }
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
          <Button onClick={onConfirm}>{title.split(" ")[0]}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
