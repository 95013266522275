

const Logo = () => {

    return (
         <img src={"/images/logoFinal.png"} alt="Ecg Logo" width="250" />
    );
};

export default Logo;
