import React from "react"
import "./chatPage.css"
import ChatBody from "./components/chatBody/ChatBody"
import { useDispatch } from "react-redux"
import { SET_MESSAGE_NOTIFICATION } from "store/actions"
import Axios from './../../utils/axios';

const currentUser = JSON.parse(localStorage.getItem("user"))
function App() {
    const dispatch = useDispatch();
  
  console.log("on it ");
  
  const resetMessageNotifications = async () => {
    await Axios.post(`/user/setFields`, {
      id: currentUser._id,
      messageUnreadNotifications: 0,
    })

    dispatch({
      type: SET_MESSAGE_NOTIFICATION,
      payload: 0,
    })

  }

  resetMessageNotifications()

  return (
    <div className="__main">
      <ChatBody />
    </div>
  )
}

export default App
