import React, { useEffect, useState } from "react"
import "./profileStyle.css"
import { AiFillCamera, AiFillPhone } from "react-icons/ai"
import { FaUserEdit } from "react-icons/fa"
import { MdLocationOn } from "react-icons/md"
import { IoIosMail } from "react-icons/io"
import { BsFillCalendarDateFill } from "react-icons/bs"
import EditIcon from "@mui/icons-material/Edit"
import { Badge, Avatar, Button, TextField, IconButton } from "@mui/material"
import { useForm } from "react-hook-form"
import Axios from "../../utils/axios"

const currentUser = JSON.parse(localStorage.getItem("user"))

export default function Index() {
  const [toggleModify, setToggleModify] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [feedback, setFeedback] = useState("")
  const [reload, setReload] = useState(false)
  const [fetchedFormData, setFetchedFormData] = useState({
    username: "nom",
    address: "address",
    phone: "phone",
    email: "email",
    birthday: "birthday",
    bio: "Écrivez votre biographie",
  })

  // Fetch current user data
  useEffect(async () => {
    let currentUserData = await Axios.get(
      `/user/profilePage/getUserInformation/${currentUser._id}`
    )
    setFetchedFormData({ ...fetchedFormData, ...currentUserData.data })
  }, [])

  const onSubmit = async (data) => {
    console.log(data)

    try {
      await Axios.patch(`/user/profilePage/modifyUser/${currentUser._id}`, data)
      setFeedback("Modification effectué.")
      setFetchedFormData({ email: fetchedFormData.email, ...data })
      setToggleModify(false)
      // for (var key in obj) {
      //   // this check can be safely omitted in modern JS engines
      //   // if (obj.hasOwnProperty(key))
      //     delete obj[key];
      // }
    } catch (error) {
      setFeedback("Une erreur s'est produite...")
    }
  }

  const handleOnChangeProfileImage = async (event) => {
    if (!event.target.files[0]) return

    const bodyFormData = new FormData()
    bodyFormData.append("image", event.target.files[0])

    await Axios.post(`/user/changeUserProfilePicture`, bodyFormData, {
      headers: {
        user_id: currentUser._id,
      },
    })

      
      setReload(!reload)
  }

  return (
    <>
      <input
        type="file"
        id="uploadFileHiddenInput"
        hidden
        onChange={handleOnChangeProfileImage}
      />
      <div className="ProfilePage">
        {/* Profile image and username */}
        <>
          <div
            className="imageBanner"
            style={{ backgroundImage: "url(/images/fond.jpg) " }}
          ></div>
          <div className="photo">
            <div>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <IconButton
                    style={{ backgroundColor: "white" }}
                    onClick={() => {
                      document.querySelector("#uploadFileHiddenInput").click()
                    }}
                  >
                    <AiFillCamera style={{ fontSize: "35px" }} />
                  </IconButton>
                }
              >
                <Avatar

                  alt="User profile picture"
                  src={`${Axios.defaults.baseURL}/userProfilePicture/${currentUser._id}.jpg?${new Date()}`}
                  sx={{ width: 150, height: 150 }}
                />
              </Badge>
              {/* <img
                src={
                  process.env.PUBLIC_URL +
                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                }
                alt="Ma photo de profil"
              /> */}
              {/* <div className="camera">
                <AiFillCamera />
              </div> */}
            </div>
            <h1>{fetchedFormData?.username} </h1>
            <p>{fetchedFormData?.specialty}</p>
          </div>
        </>
        <section className="profilePageBody">
          {/* Bio */}
          <div className="bio">
            <h2>Biographie</h2>
            {toggleModify ? (
              <TextField
                maxRows={10}
                multiline
                defaultValue={fetchedFormData?.bio}
                variant="outlined"
                {...register("bio")}
              />
            ) : (
              <p>{fetchedFormData?.bio}</p>
            )}
          </div>
          {/* Form data */}
          <div>
            <form className="contact" onSubmit={handleSubmit(onSubmit)}>
              <h2>
                Informations de contact
                <Button
                  onClick={() => {
                    if (toggleModify) return setToggleModify(false)

                    setToggleModify(true)
                  }}
                >
                  <EditIcon />
                </Button>
              </h2>
              <div className="contact-flex">
                <div className="emoji">
                  <FaUserEdit />
                </div>
                {toggleModify ? (
                  <TextField
                    defaultValue={fetchedFormData.username}
                    variant="outlined"
                    {...register("username")}
                  />
                ) : (
                  <p>{fetchedFormData.username}</p>
                )}
              </div>
              <div className="contact-flex">
                <div className="emoji">
                  <MdLocationOn />
                </div>
                {toggleModify ? (
                  <TextField
                    defaultValue={fetchedFormData.address}
                    {...register("address")}
                  />
                ) : (
                  <p>{fetchedFormData.address}</p>
                )}
              </div>
              <div className="contact-flex">
                <div className="emoji">
                  <AiFillPhone />
                </div>
                {toggleModify ? (
                  <TextField
                    defaultValue={fetchedFormData.phone}
                    {...register("phone")}
                  />
                ) : (
                  <p>{fetchedFormData.phone}</p>
                )}
              </div>
              <div className="contact-flex">
                <div className="emoji">
                  <IoIosMail />
                </div>
                <p>{fetchedFormData.email}</p>
              </div>
              <div className="contact-flex">
                <div className="emoji">
                  <BsFillCalendarDateFill />
                </div>
                {toggleModify ? (
                  <TextField
                    defaultValue={fetchedFormData.birthday}
                    {...register("birthday")}
                  />
                ) : (
                  <p>{fetchedFormData.birthday}</p>
                )}
              </div>
              {toggleModify && <Button type="submit">Enregistrer</Button>}
              <p>{feedback}</p>
            </form>
          </div>
        </section>
      </div>
    </>
  )
}
