import { useState, useRef, useEffect } from "react"
import "./smallerNotification.css"
import { Link, useNavigate } from "react-router-dom"

// material-ui
import { useTheme } from "@mui/material/styles"
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material"

// third-party
import PerfectScrollbar from "react-perfect-scrollbar"

// project imports
import MainCard from "ui-component/cards/MainCard"
import Transitions from "ui-component/extended/Transitions"
import NotificationList from "./NotificationList"

// assets
import { IconBell } from "@tabler/icons"
import NotificationPage from "Pages/NotificationPage/NotificationPage"
import Axios from "../../../../utils/axios"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

// notification status options
const status = [
  {
    value: "all",
    label: "Toutes les notifications",
  },
  {
    value: "Messages",
    label: "Messages",
  },
  {
    value: "Danger",
    label: "Danger",
  },
  {
    value: "Avertissement",
    label: "Avertissement",
  },
  {
    value: "Observation",
    label: "Observation",
  },
]

// ==============================|| NOTIFICATION ||============================== //
const currentUser = JSON.parse(localStorage.getItem("user"))
const NotificationSection = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { deviceNotification, messageNotification } = useSelector(
    (state) => state.notification
  )

  // Set unread notification to 0
  useEffect(() => {}, [])

  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"))

  const [open, setOpen] = useState(false)
  const [value, setValue] = useState("")
  const anchorRef = useRef(null)

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  const handleCloseOpenNotificationHeader = async () => {
    setOpen((prevOpen) => !prevOpen)
    // dispatch({ type: CHANGE_NOTIFICATION_COUNT, payload: 0 })
    // await Axios.patch("/user/setFields", {
    //   id: currentUser._id,
    //   fields: {
    //     allUnreadNotifications: 0,
    //   },
    // })
  }

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        <Badge
          badgeContent={deviceNotification}
          color="error"
        >
          <ButtonBase sx={{ borderRadius: "12px" }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: "all .2s ease-in-out",
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light,
                },
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleCloseOpenNotificationHeader}
              color="inherit"
            >
              <IconBell stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        </Badge>
      </Box>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? "top" : "top-right"}
            in={open}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ pt: 2, px: 2 }}
                      >
                        <Grid item>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle1">
                              Toutes les notifications
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <PerfectScrollbar
                        style={{
                          height: "100%",
                          maxHeight: "calc(100vh - 205px)",
                          overflowX: "hidden",
                        }}
                      >
                        {/* <Grid container direction="column" spacing={2}>
                          <Grid item xs={12}>
                            <Box sx={{ px: 2, pt: 0.25 }}>
                              <TextField
                                id="outlined-select-currency-native"
                                select
                                fullWidth
                                value={value}
                                onChange={(event) => {
                                  if (event?.target.value)
                                    setValue(event?.target.value)
                                }}
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {status.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid> */}
                        {/* <NotificationList /> */}
                        <div className="smallerNotification">
                          <NotificationPage />
                        </div>
                      </PerfectScrollbar>
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardActions sx={{ p: 1.25, justifyContent: "center" }}>
                    <Button
                      onClick={() => navigate("/dashboard/notifications")}
                      size="small"
                      disableElevation
                    >
                      Voir tous
                    </Button>
                  </CardActions>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  )
}

export default NotificationSection
