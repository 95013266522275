import axios from "axios"

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  var baseURL = 'http://localhost:8001'
} else {
  // production code
  var baseURL = 'https://oumaima-fatnasi.herokuapp.com'
}
const Axios = axios.create({
    baseURL: baseURL
});
export default Axios;

Axios.interceptors.request.use((req) => {
    if (localStorage.getItem("user")) {
      req.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("user")).accesToken
      }`
    }
    return req
  })
  