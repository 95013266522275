import { useEffect, useState } from "react"
import "./NotificationPage.css"

import Axios from "../../utils/axios"
import moment from "moment"
import {
  Avatar,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  IconButton,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import MessageIcon from "@mui/icons-material/Message"
import { SET_DEVICE_NOTIFICATION } from "store/actions"
import { useDispatch } from "react-redux"

var lastClick = 0
var delay = 3000
const currentUser = JSON.parse(localStorage.getItem("user"))

const NotificationPage = () => {
  const [fetchedNotifications, setFetchedNotifications] = useState([])
  const [filter, setFilter] = useState("")
  const dispatch = useDispatch()

  // Fetch
  useEffect(async () => {
    setFetchedNotifications(
      (await Axios.get(`/notification/getUserNotifications/${currentUser._id}`))
        .data
    )
  }, [])

  // Handles
  const handleDeleteAllNotifications = async () => {
    await Axios.delete(`/notification/deleteAll/${currentUser._id}`)
    setFetchedNotifications([])
  }
  const handleDeleteOneNotification = async (selectedNotification) => {
    await Axios.delete(`/notification/deleteOne/${selectedNotification._id}`)

    setFetchedNotifications(
      fetchedNotifications.filter(
        (notification) => notification._id !== selectedNotification._id
      )
    )
  }
  const handleRemoveDeviceNotification = async () => {
    if (lastClick >= Date.now() - delay) return
    lastClick = Date.now()

    // Remove notification Redux
    dispatch({
      type: SET_DEVICE_NOTIFICATION,
      payload: 0,
    })

    // Remove notification in db

    await Axios.patch("/user/setFields", {
      id: currentUser._id,
      fields: { deviceNotification: 0 },
    })
  }

  const selectOptions = ["Danger", "Avertissement", "Observation"]

  {
    /* //! ------------- Return ----------------- */
  }
  return (
    <div className="notificationPage" onClick={handleRemoveDeviceNotification}>
      <h1>Page Notification</h1>
    </div>
  )
  function select(props) {
    return (
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>{props.label} </InputLabel>
        <Select {...props}>
          {props.options.map((option, key) => (
            <MenuItem
              key={key}
              value={option === "Avertissement" ? "Warning" : option}
            >
              {option}{" "}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  function notificationElement(notification, key) {
    let src = `${process.env.PUBLIC_URL}/images/${notification.type}.png`

    return (
      <div className={"element " + notification.type} key={key}>
        {notification.type === "Message" ? (
          <MessageIcon color="primary" style={{ fontSize: "4.5rem" }} />
        ) : (
          <img src={src} alt="Notification Icon" />
        )}
        <div style={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h3>{notification?.title}</h3>
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteOneNotification(notification)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <p>{notification?.message}</p>
            <p className="date">
              {moment(notification.createdAt).format("DD-MM-YYYY HH-mm")}
            </p>
          </Stack>
        </div>
      </div>
    )
  }
}

export default NotificationPage

// return (
//   <div className="notificationPage">
//     {fetchedNotifications.map((device, key) => {
//       if (device.alerts.length != 0) {
//         return device.alerts.map((alert, key) => (
//           <>{notificationElement(alert, device, key)}</>
//         ))
//       }
//     })}
//   </div>
// )
// function notificationElement(alert, device, key) {
//   let src = `${process.env.PUBLIC_URL}/images/${alert.warningType}.png`
//   return (
//     <div className={"element " + alert.warningType} key={key}>
//       <img src={src} alt="Notification Icon" />
//       <div className="elementInner">
//         <h3>L'appareil {device.deviceId} a détecté une anomalie passable</h3>

//         <p className="date">
//           {alert.time.substring(0, 10) + " " + alert.time.substring(11, 16)}
//         </p>
//       </div>
//     </div>
//   )
// }
