import React, { Component, useState, useEffect } from "react"
import "./chatList.css"
import Axios from "../../../../utils/axios"
import Avatar from "./Avatar"
import { Badge } from "@mui/material"
import { useSelector } from "react-redux"
import { BadgeUnstyled } from "@mui/base"

export default function ChatList({ activeUser, setActiveUser }) {
  const [userInput, setUserInput] = useState("")
  const [fetchedPatients, setFetchedPatients] = useState([])
  const { unreadConversation } = useSelector((state) => state.notification)

  // On user input --> Search
  useEffect(async () => {
    const timer = setTimeout(async () => {
      if (!userInput)
        return setFetchedPatients((await Axios.get("/user/getPatients")).data)
      setFetchedPatients(
        (await Axios.get(`/user/searchPatient/${userInput}`)).data
      )
    }, 1000)
    return () => clearTimeout(timer)
  }, [userInput])

  return (
    <div className="main__chatlist">
      {/* {searchHeader()} */}
      {chatBody()}
    </div>
  )

  function chatBody() {
    return (
      <div className="chatlist__items">
        {fetchedPatients.map((patient, key) => (
          <Badge
            variant="dot"
            style={{ display: "inline" }}
            badgeContent={
              unreadConversation.indexOf(patient._id) !== -1 ? " " : 0
            }
            color="error"
          >
            <div
              key={key}
              style={{ animationDelay: `0.1s` }}
              onClick={() => setActiveUser(patient)}
              className={`chatlist__item ${
                activeUser === patient ? "active" : ""
              } `}
            >
              <Avatar
                image="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU"
                isOnline={"active"}
              />

              <div className="userMeta">
                <p>{patient.username}</p>
                <span className="activeTime"> </span>
              </div>
            </div>
          </Badge>
        ))}
      </div>
    )
  }

  function searchHeader() {
    return (
      <div>
        <h3 style={{ textAlign: "center" }}>Liste des contacts</h3>
        {/* {patientToContact} */}
        <div className="chatlist__heading"></div>
        <div className="chatList__search">
          <div className="search_wrap">
            <button className="search-btn">
              <i className="fa fa-search"></i>
            </button>
            <input
              type="text"
              onChange={(event) => setUserInput(event.target.value)}
              placeholder="Search Here"
              required
            />
          </div>
        </div>
      </div>
    )
  }
}

// Handle user input
// useEffect(() => {
//   userToDisplay = []
//   if (!userInput) {
//     return
//   }
//   var elemLowercase
//   const timer = setTimeout(() => {
//     allChatUsers.map((username, key) => {
//       elemLowercase = username.toLowerCase()

//       if (elemLowercase.search(userInput.toLowerCase().trim()) > -1) {
//         userToDisplay.push(username)
//       }
//     })
//     setRenderer(!Renderer)
//   }, 1000)

//   return () => clearTimeout(timer)
// }, [userInput])
