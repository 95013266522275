import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import AddIcon from "@mui/icons-material/Add"
import { IconButton } from "@mui/material"
import Axios from "./../../utils/axios"

export default function AddObservationDialog(props) {
  console.log(props)
  const [open, setOpen] = React.useState(false)
  const [observation, setObservation] = React.useState()

  const handleSubmit = async () => {
    handleClose()
    console.log(observation)

    await Axios.post(`/custom`, {
      scheme: "user",
      method: "patch",
      _id : props.selectedPatient._id,
      $addToSet: { observations: observation },
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div
      style={{ display: "inline" }}
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      <IconButton onClick={handleClickOpen}>
        <AddIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ajouter une observation</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Observation"
            fullWidth
            variant="standard"
            onChange={(e) => setObservation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleSubmit}>Ajouter</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
