import React, { Component, useState, createRef, useEffect, useRef } from "react"
import $ from "jquery"
import "./chatContent.css"
import ChatItem from "./ChatItem"
import Axios from "../../../../utils/axios"
import { socket } from "App"
import { useDispatch } from "react-redux"
import { PULL_FROM_UNREAD_CONVERSATION } from "store/actions"
import { useSelector } from "react-redux"
import { Avatar } from "@mui/material"

var lastClick = 0;
var delay = 3000;
const currentUser = JSON.parse(localStorage.getItem("user"))
export default function ChatContent({ activeUser }) {
  const [fetchedConversation, setFetchedConversation] = useState(["Potato"])
  const [userInputMessage, setUserInputMessage] = useState("")
  const myRef = useRef(null)
  const { unreadConversation } = useSelector((state) => state.notification)

  const dispatch = useDispatch()

  // Scroll To end
  var messagesEndRef = createRef(null)
  useEffect(() => {
    const el = document.getElementsByClassName("content__body")[0]

    if (el) {
      el.scrollTop = el.scrollHeight
    }
  }, [fetchedConversation])

  // Socket conversation
  useEffect(() => {
    socket.on("receivingANewMessage", (data) => {
      setFetchedConversation((prev) => [...prev, data])
    })
  }, [])

  // Fetch conversation
  useEffect(async () => {
    console.log(activeUser)
    if (!activeUser) return
    let fetchedConversation = (
      await Axios.get(
        `/chat/getConversation?senderId=${currentUser._id}&receiverId=${activeUser._id}`
      )
    ).data
    setFetchedConversation(fetchedConversation)

    handleRemoveConversationNotification()
  }, [activeUser])

  // Remove notification DB
  useEffect(async () => {
    if (!activeUser) return
    await Axios.patch("/user/setFields", {
      id: currentUser._id,
      fields: { unreadConversation: unreadConversation },
    })
  }, [unreadConversation])

  // Submit send message
  const submit = async () => {
    if (!userInputMessage) return

    await Axios.post(`/chat/sendMessage`, {
      senderId: currentUser._id,
      receiverId: activeUser._id,
      message: userInputMessage,
    })

    // setFetchedConversation([...fetchedConversation, newMessage])
    setUserInputMessage("")
  }

  const handleRemoveConversationNotification = () => {
    if (lastClick >= Date.now() - delay) return
    lastClick = Date.now()


    if (unreadConversation.indexOf(activeUser._id) === -1) return

    // Remove notification Redux
    dispatch({
      type: PULL_FROM_UNREAD_CONVERSATION,
      payload: activeUser._id,
    })
  }

  return (
    <div className="main__chatcontent">
      {activeUser && (
        <>
          {header()}
          {bodyConversation()}
          {footerInput()}
        </>
      )}
    </div>
  )

  function footerInput() {
    return (
      <div className="content__footer">
        <div className="sendNewMessage">
          <input
            type="text"
            placeholder="Envoyez un message"
            onKeyPress={(e) => {
              if (e.key == "Enter") submit()
            }}
            onChange={(e) => {
              setUserInputMessage(e.target.value)
            }}
            value={userInputMessage}
          />
        </div>
      </div>
    )
  }

  function bodyConversation() {
    return (
      <div
        ref={myRef}
        className="content__body"
        onClick={handleRemoveConversationNotification}
      >
        <div className="chat__items">
          {fetchedConversation.map((message, index) => (
            <ChatItem
              animationDelay={index + 2}
              key={index + 1}
              user={message.senderId === currentUser._id ? "me" : "other"}
              msg={message.message}
              image={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU"
              }
            />
          ))}
          <div id="anchorName2" ref={messagesEndRef} />
        </div>
      </div>
    )
  }

  function header() {
    return (
      <div className="content__header">
        <div className="blocks">
          <div className="current-chatting-user">
          <Avatar src="https://images.unsplash.com/photo-1612349316228-5942a9b489c2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" />
            <p>{activeUser.username} </p>
          </div>
        </div>

        <div className="blocks">
          <div className="settings">
            <button className="btn-nobg">
              <i className="fa fa-cog"></i>
            </button>
          </div>
        </div>
      </div>
    )
  }
}
