import { createContext, useContext, useEffect, useState } from "react"
import useStyles from "./style"

import {
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import "./PatientPage.css"
import AddPatient from "./AddPatient"
import Axios from "../../utils/axios"
import FichePatient from "./fichePatient"
import ShowConfirmationDialog from "ui-component/ShowConfirmationDialog"
import AddObservationDialog from './AddObservationDialog';

export const patientListContext = createContext(null)

const tableHeader = (
  <TableHead>
    <TableRow>
      <TableCell align="center" >Nom</TableCell>
      <TableCell align="center" >Cin</TableCell>
      <TableCell align="center" >Email</TableCell>
      <TableCell align="center" >Action</TableCell>
    </TableRow>
  </TableHead>
)
const Patient = (props) => {
  console.log(props)
  const classes = useStyles()
  const [showAddPatient, setShowAddPatient] = useState(false)
  const [fetchedPatients, setFetchedPatients] = useState([])
  const [patientData, setPatientData] = useState()
  const [rerender, setRerender] = useState(false)
  const [ficheOpen, setFicheOpen] = useState(false)

  const [patientListFeedback, setPatientListFeedback] = useState("")
  // Get Patient list
  useEffect(async () => {
    setPatientData(null)
    setFetchedPatients(
      await Axios.get("/patientList/getPatients").then((res) => {
        return res.data
      })
    )
  }, [rerender])

  const handleDeletePatient = async (selectedPatient) => {
    await Axios.delete(`/user/deleteUser/${selectedPatient._id}`)

    setPatientListFeedback(`${selectedPatient.username} a été supprimé.`)

    setFetchedPatients(
      fetchedPatients.filter((patient) => patient._id !== selectedPatient._id)
    )
  }

  const handleAddNewPatient = () => {
    setShowAddPatient(true)
    setPatientData(null)
  }

  return (
    <patientListContext.Provider
      value={{
        setPatientListFeedback,
        rerender,
        setRerender,
        ficheOpen,
        setFicheOpen,
        patientData,
        setPatientData,
      }}
    >
      <div className="patientPage">
        <div className={classes.flex}>
          <div>
            <h2>Liste des patients</h2>
            <h3>{patientListFeedback} </h3>
          </div>
          {/* Add new patient */}
          <IconButton
            color="primary"
            aria-label="add"
            className={classes.iconButton}
            onClick={handleAddNewPatient}
          >
            <AddIcon />
          </IconButton>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            {tableHeader}
            {tableBody()}
          </Table>
        </TableContainer>
        {addModifyPatient()}
      </div>
      {/*  Patient file */}
      <FichePatient showMe={ficheOpen} />
    </patientListContext.Provider>
  )

  function addModifyPatient() {
    return (
      <Dialog
        open={showAddPatient}
        onClose={() => setShowAddPatient(false)}
      >
        <Paper className={classes.paper}>
          <AddPatient
            setShowAddPatient={setShowAddPatient}
            patientData={patientData}
          />
        </Paper>
      </Dialog>
    )
  }

  function tableBody() {
    return (
      <TableBody>
        {fetchedPatients.map((patient, key) => (
          <TableRow
            onClick={() => {
              setPatientData(patient)

              setFicheOpen(true)
            }}
            key={key}
          >
            <TableCell align="center" >{patient.username}</TableCell>
            <TableCell align="center" >{patient?.cin}</TableCell>
            <TableCell align="center" >{patient.email}</TableCell>
            <TableCell align="center" >
              <IconButton>
                <EditIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowAddPatient(true)
                    setPatientData(patient)
                  }}
                  style={{ color: "#1bb719", cursor: "pointer" }}
                />
              </IconButton>

              {/* Delete Patient Confirmation dialog */}
              <ShowConfirmationDialog
                title="Supprimer un patient"
                description={[
                  "Êtes vous sur de vouloir supprimer ce patient : ",
                  <span style={{ color: "#1bb719" }}> {patient?.username} </span>,
                  " ?",
                ]}
                onConfirm={() => {
                  handleDeletePatient(patient)
                }}
                button={
                  <DeleteForeverIcon
                    style={{ color: "#b54827", cursor: "pointer" }}
                  />
                }
              />

              <AddObservationDialog selectedPatient={patient} />

            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }
}

export default Patient

//  <div className="container">
//         <h3>LISTES DES PATIENTS</h3>

//         <div className="tableContainer">
//           <table>
//             <tbody>
//               <tr>
//                 <th>Actions</th>
//                 <th>Nom</th>
//                 <th>Prenon</th>
//                 <th>CIN</th>
//                 <th>Email</th>
//                 <th>Mot de passe</th>
//                 <th>Date naissance</th>
//                 <th>Num mobile</th>
//                 <th>Genre</th>
//               </tr>
//               <tr>
//                 <td>
//                   {/* <p onClick={() => this.submitPatient()} className="plus">
//                                         +
//                                     </p> */}
//                   <p>+</p>
//                 </td>
//                 <td>
//                   {/* //! ------------------------- Medical Folder Input
//                                           --------------------------- | */}
//                   <input
//                     type="text"
//                     onChange={(event) =>
//                       this.setState({ MedicalFolder: event.target.value })
//                     }
//                     placeholder="Numéro du dossier médical"
//                   />
//                 </td>
//                 <td>
//                   {/* //! ----------------------------- Select Doctor ------------------------------ | */}
//                   <select value="null" id="">
//                     <option value="null" disabled>
//                       Choisir un médecin
//                     </option>
//                   </select>
//                   {/* <select
//                                         value={this.state.SelectedDoctor}
//                                         onChange={(event) => this.setState({ SelectedDoctor: event.target.value })}
//                                     >
//                                         <option value="null" disabled>
//                                             Choisir un médecin
//                                         </option>
//                                         {this.state.Doctors.map((elem, key) => (
//                                             <option value={elem.username}>{elem.username}</option>
//                                         ))}
//                                     </select> */}
//                 </td>
//                 <td>
//                   {/* //! ----------------------------- Doctor Number ------------------------------ | */}
//                   <input type="text" />
//                   {/* <input
//                                         onChange={(event) => this.setState({ DoctorNumber: event.target.value })}
//                                         type="text"
//                                         placeholder="Contact numéro"
//                                     /> */}
//                 </td>
//                 <td>
//                   {/* //! ----------------------------- Observations ------------------------------- | */}
//                   <textarea name="" id="" cols="30" rows="10"></textarea>
//                   {/* <textarea
//                                         onChange={(event) => this.setState({ Observations: event.target.value })}
//                                         type="text"
//                                         placeholder="Remarques"
//                                     /> */}
//                 </td>
//                 <td>
//                   {/* //! ----------------------------- Select Device ------------------------------ | */}
//                   <select value="null" id="">
//                     <option value="null" disabled>
//                       Choisir un appareil
//                     </option>
//                   </select>
//                   {/* <select
//                                         value={this.state.SelectedDevice}
//                                         onChange={(event) => this.setState({ SelectedDevice: event.target.value })}
//                                     >
//                                         <option disabled value="null">
//                                             Choisir un appareil
//                                         </option>
//                                         {this.state.Devices.map((elem, key) => (
//                                             <option value={elem.deviceId}>{elem.deviceId}</option>
//                                         ))}
//                                     </select> */}
//                 </td>
//               </tr>
//               {/* {Line()}
//                         {Line()}
//                         {Line()}
//                         {Line()} */}
//             </tbody>
//           </table>
//         </div>
//       </div>
