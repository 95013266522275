import React, { Component, useState, useEffect } from "react"
import "./chatBody.css"
import ChatList from "../chatList/ChatList"
import ChatContent from "../chatContent/ChatContent"
import UserProfile from "../userProfile/UserProfile"

export default function ChatBody() {

  const [activeUser, setActiveUser] = useState(null)

  return (
    <div className="main__chatbody">
      <ChatList activeUser={activeUser} setActiveUser={setActiveUser} />
      <ChatContent activeUser={activeUser} />

      <UserProfile activeUser={activeUser} />
    </div>
  )
}
