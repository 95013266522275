import { lazy } from "react"

// project imports
import MainLayout from "layout/MainLayout"
import Loadable from "ui-component/Loadable"
import DoctorPage from "Pages/DoctorPage/DoctorPage"
import EtatDetaille from "Pages/Etat Detallé"
import Messagerie from "Pages/ChatPage/ChatPage"
import Patient from "Pages/PatientPage/PatientPage"
import Profile from "Pages/ProfilePage/ProfilePage"
import AccountSetting from "../Pages/AccountSettingPage/AccountSettingsPage"
import NotificationPage from "../Pages/NotificationPage/NotificationPage"
import AppointmentPage from "Pages/AppointmentPage/AppointmentPage"

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import("Pages/DashboardPage/DashboardPage")))


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "/dashboard/default",
      element: <DashboardDefault />,
    },
    {
      path: "/dashboard/etatDetaille",
      element: <EtatDetaille />,
    },
    {
      path: "/dashboard/patient",
      element: <Patient />,
    },
    {
      path: "/dashboard/appointment",
      element: <AppointmentPage />,
    },
    {
      path: "/dashboard/doctorPage",
      element: <DoctorPage />,
    },
    {
      path: "/dashboard/profil",
      element: <Profile />,
    },
    {
      path: "/dashboard/notifications",
      element: <NotificationPage />,
    },
    {
      path: "/dashboard/accountSetting",
      element: <AccountSetting />,
    },
    {
      path: "/dashboard/messagerie",
      element: <Messagerie />,
    },
  ],
}

export default MainRoutes
