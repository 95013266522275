// assets
import { IconBrandChrome, IconHelp } from "@tabler/icons"
import { BiLogOut } from "react-icons/bi"

// constant
const icons = { IconBrandChrome, IconHelp }

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: "Déconnexion",
  title: "Déconnexion",
  type: "group",
  mode: "logOut",
  style: { position: "absolute", bottom: 0 },
  children: [
    {
      id: "Déconnexion",
      title: "Déconnexion",
      type: "item",
      url: "/login",
      icon: BiLogOut,
      breadcrumbs: false,
    },
  ],
}

export default other
