// assets
import { IconKey } from '@tabler/icons';
import {
  FaHeartbeat,
  FaBell,
  FaHome,
  FaUser,
  FaFileMedicalAlt,
} from "react-icons/fa"
import { MdPermContactCalendar } from "react-icons/md"
import { AiFillWechat } from "react-icons/ai"
import { IoSettings } from "react-icons/io"

// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: "pages",
  // title: "Titre",
  // caption: 'Pages Caption',
  type: "group",
  children: [
    {
      id: "Profil",
      title: "Profil",
      type: "item",
      url: "/dashboard/profil",
      icon: icons.IconKey,
      breadcrumbs: false,
    },
    {
      id: "Notifications",
      title: "Notifications",
      url: "/dashboard/notifications",
      type: "item",
      icon: FaBell,
    },
    {
      id: "Messagerie",
      title: "Messagerie",
      type: "item",
      url: "/dashboard/messagerie",

      icon: AiFillWechat,
    },
    {
      id: "Paramètre profile",
      title: "Paramètre profile",
      type: "item",
      url: "/dashboard/accountSetting",

      icon: icons.IconKey,
    },
  ],
}

export default pages;
